.bg{
    
    margin-top: 0rem;
    background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.bg h1{
    color: white;
    font-size: 3rem;
}
.container{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-top: 3rem;
}
.left h1{
    
    text-align: left;
    font-family: 'Fjalla One', sans-serif;
    margin-bottom: 1rem;
}
.left p{
    font-size: .9rem;
    text-align: left;
    width: 35rem;
}
.left h4{
    text-align: left;
}
.right img{
    width: 30rem;
    border-radius: .8rem;
}

@media screen and (max-width:768px) {
    .bg{
    
        margin-top: 0rem;
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .bg h1{
        color: white;
        font-size: 2rem;
    }
    .container{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 3rem;
        width: 100%;
        padding: 1rem;
    }
    .left h1{
        font-size: 1.2rem;
        text-align: left;
        font-family: 'Fjalla One', sans-serif;
        margin-bottom: 1rem;
        width: 100%;
    }
    .left p{
        font-size: .8rem;
        text-align: left;
        width: 100%;
        margin-bottom: 1rem;
    }
    .left h4{
        text-align: left;
        font-family: 'Fjalla One', sans-serif;
        margin-bottom: .5rem;
    }
    .right img{
        width: 90%;
        border-radius: .8rem;
    }
}