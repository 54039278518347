.container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: #103a52;
    padding-top: 2rem;
   
    
   
}
.r1{
    display: flex;
    flex-direction: column;
    background: radial-gradient(#014918cf,#246c95);
   justify-content: space-between;
   gap: 1rem;
   width: 30rem;
   
   padding: 1rem;
}
.logo img{
    width: 12rem;
}
.Addrow{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}
.Addcol{
    display: flex;
    flex-direction: column;
}
.address{
    display: flex;
    flex-direction: column;
    border: 0;
    padding:1rem;
    width: 27rem;
    gap:.5rem;
    background-color: #a9b6c080;
}
.address i{
    font-size: 1.5rem;
    border: 1.5px dashed white;
    background: white;
    padding: .5rem;
    
    outline: 1.5px dashed white;
    outline-offset: 2px;
    color: #103a52;
}
.name{
 font-size: .9rem;
 font-weight: 600;  
 width: 10rem; 
}
.address p{
    width: 20rem;
    font-size: .8rem;
    color: white;
}
.contact{
    display: flex;
    flex-direction: column;
    border: 0;
    padding:1rem;
    width: 27rem;
    gap:.5rem;
    background-color: #8db2ca80;
}
.contact i{
    font-size: 1.5rem;
    border: 1px dashed #222;
    padding: .5rem;
    background: white;
    outline: 1.5px dashed white;
    outline-offset: 2px;
    color: #103a52;
}
.contact p{
    width: 20rem;
    font-size: .8rem;
    color: white;
    
}
.email{
    display: flex;
    flex-direction: column;
   
    border: 0;
    padding:1rem;
    width: 27rem;
    gap:.5rem;
    background-color: #86b6d380;
}
.email i{
    font-size: 1.5rem;
    border: 2px dashed  white;
    padding: .5rem;
    background: white;
    outline: 1.5px dashed white;
    outline-offset: 2px;
    color: #103a52;
}
.email p{
    width: 20rem;
    font-size: .8rem;
    color: white;
    
}
.r2{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    transform: translateX(-11rem);
    margin-top: -7rem;
    
}
.headQuick{
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    border-bottom: 2px solid white;
    padding-bottom: .5rem;
    
}
.links a{
    color: white;
    text-decoration: none;
    font-size: .9rem;
    
}
.r3{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 2rem;
    transform: translateX(-4rem);
    
    
}
.headServices{
    font-size: 1.2rem;
    font-weight: 600;
    color: white;
    border-bottom: 2px solid white;
    padding-bottom: .5rem;

}
.servicesLinks a{
    color: white;
    text-decoration: none;
    font-size: .9rem;
}
.col{
    display: flex;
    flex-direction: column;
}
.row{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    margin-bottom: 1rem;
    
}
.copyright p{
    color: white;
    font-size: .9rem;
    margin-top: 1rem;
    padding: 1rem;
    margin-left: -40rem;
   
    
    width: 50rem;
}
 .heartImg img{
    width: 1rem;
}
.socialmedia{
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.r1 img{
    width: 1.5rem;
}



@media screen and (max-width: 768px){
    .container{
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #103a52;
        padding-top: 2rem;
        overflow: hidden;
       }
       .r1{
        display: flex;
        flex-direction: column;
        background: radial-gradient(#014918cf,#246c95);
       justify-content: space-between;
       gap: 1rem;
       width: 100%;
       
       padding: 1rem;
    }
    .address{
        display: flex;
        flex-direction: column;
        border: 0;
        padding:1rem;
        width: 95%;
        gap:.5rem;
        background-color: #a9b6c080;
    }
    .address i{
        font-size: 1rem;
        border: 1px dashed white;
        background: white;
        padding: .5rem;
        
        outline: 1px dashed white;
        outline-offset: 2px;
        color: #103a52;
    }
    .name{
     font-size: 1rem;
     font-weight: 600;  
     width: 10rem; 
    }
    .address p{
        width: 100%;
        font-size: .8rem;
        color: white;
    }
    .contact{
        display: flex;
        flex-direction: column;
        border: 0;
        padding:1rem;
        width:95%;
        gap:.5rem;
        background-color: #8db2ca80;
    }
    .contact i{
        font-size: 1rem;
        border: 1px dashed #222;
        padding: .5rem;
        background: white;
        outline: 1px dashed white;
        outline-offset: 2px;
        color: #103a52;
        transform: translateX(-5rem);
    }
    .contact p{
        width: 100%;
        font-size: .8rem;
        color: white;
        
    }
    .email{
        display: flex;
        flex-direction: column;
       
        border: 0;
        padding:1rem;
        width: 95%;
        gap:.5rem;
        background-color: #86b6d380;
    }
    .email i{
        font-size: 1rem;
        border: 2px dashed  white;
        padding: .5rem;
        background: white;
        outline: 1px dashed white;
        outline-offset: 2px;
        color: #103a52;
        margin-left: -1rem;
    }
    .email p{
        width: 100%;
        font-size: .8rem;
        color: white;
        margin-left: 2rem;
        
    }
    .r2{
        transform: translateY(-1rem);
        padding-left: 1rem;

    }
    .r2 .headQuick{
        width: 7rem;
        color: white;
        
    }
    .r3{
        transform: translateX(0rem);
        padding-right: 1rem;
    }
    .r3 .headServices{
        width: 5rem;
    }
    .servicesLinks{
        width: 12rem;
    }
    .copyright{
        width: 100%;
       
    }
    .copyright p{
       transform: translateX(27rem);
       font-size: .5rem;
    }
    .heartImg img{
        width: .7rem;
    }
    
       

    
    
}