.headhold {
    margin-top: 0rem;
    height: 15rem;
    background-image: linear-gradient(to right, #116a2dcf, #103a52c4);
    margin-bottom: 3rem;
}

.head {
    text-align: center;
    font-family: 'Fjalla One', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    color: white;
    transform: translateY(5rem);
}

.intro {
    text-align: left;
    margin-bottom: 1rem;
    font-family: 'Fjalla One', sans-serif;
    font-size: 2rem;
    font-weight: 500;
}

.introhold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 4rem;
}

.introL {
    width: 31rem;
   
}

.sme {
    border-radius: 1.2rem;
    width: 34rem;
    height: 48rem;
}

.pur {
    font-family: 'Fjalla One', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.pur2 {
    font-family: 'Fjalla One', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.mark {
    margin-top: 1.2rem;
    width: 1rem;
    height: 1rem;
    text-align: left;
}

.introLTxt {
    font-family: 'poppins', sans-serif;
    text-align: left;
    font-size: .9rem;
}

.pointsHold {
    margin-top: 0rem;
    font-family: 'poppins', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    text-align: left;
}
.pointsHold p{
    text-align: left;
}

@media screen and (max-width:768px) {
    .headhold {
        margin-top: 0rem;
        height: 8rem;
        background-image: linear-gradient(to right, #116a2dcf, #103a52c4);
        margin-bottom: 3rem;
    }
    
    .head {
        text-align: center;
        font-family: 'Fjalla One', sans-serif;
        font-size: 2rem;
        font-weight: 600;
        color: white;
        transform: translateY(3rem);
    }
    .introhold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        width: 100%;
        padding: 1rem;
    }
    .introL{
        width: 100%;
    }
    .introLTxt {
        font-family: 'poppins', sans-serif;
        text-align: left;
        font-size: .8rem;
        width: 100%;
    }
    .intro {
        text-align: left;
        margin-bottom: 1rem;
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.2rem;
        font-weight: 500;
        width: 100%;
    }
    .introR img{
        width: 90%;
        height: 20rem;
    }
    .pur{
        font-size: 1rem;
    }
    .pur2{
        font-size: .8rem;
    }
}