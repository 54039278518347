 @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

 .headhold {
    margin-top: 0rem;
     text-align: center;
     padding-top: 5rem;
     padding-bottom: 5rem;
     font-family: 'Fjalla One', sans-serif;
     font-size: 3rem;
     color: white;
     background-image: linear-gradient(to right, #116a2dcf, #103a52c4);
 }

 .introhead {
     
     font-family: 'Fjalla One', sans-serif;
     text-align: left;
     margin-bottom: 1rem;
     
 }
 .introL p{
    font-size: .9rem;
    text-align: left;
 }

 .introhead2 {
     margin-top: 6rem;
     text-align: center;
     font-family: 'Fjalla One', sans-serif;
     font-size: 2.1rem;
     font-weight: 600;
 }

 .introhead3 {
     margin-top: 1rem;
     text-align: center;
     font-family: 'Fjalla One', sans-serif;
     font-size: 1rem;
     font-weight: 500;
     margin-bottom: 1rem;
 }

 .introhead4 {
     font-size: .9rem;
     text-align: center;
     margin: 0 4rem;
 }

 .introhold {
     display: flex;
     flex-direction: row;
     justify-content: center;
     gap: 3rem;
     margin-top: 4rem;
 }

 .introL {
     width: 35rem;
 }

 .introImg {
    
     width: 30rem;
     
     border-radius: .8rem;
 }

 .bgimg {
     margin-top: 2rem;
     padding-top: 2rem;
     padding-bottom: 6rem;
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-image: url("./asset/about2.jpg");
     background-size: cover;
     height: 38rem;
 }

 .top {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     
 }

 .advHead {
     margin-top: 4rem;
     text-align: center;
     font-size: 2.2rem;
     font-weight: 600;
     font-family: 'Fjalla One', sans-serif;
 }

 .adv {
     display: flex;
     flex-direction: row;
     justify-content: center;
 }

 .grow {
     margin-top: 4rem;
     margin-bottom: 4rem;
     padding-top: 1rem;
     padding-bottom: 3rem;
     background-image: linear-gradient(to right, #116a2dcf, #103a52c4);
 }

 .growTxt {
     text-align: center;
     font-family: 'Fjalla One', sans-serif;
     font-weight: 600;
     font-size: 3.2rem;
     color: white;
 }

 .growBtn {
     margin: auto;
     display: block;
     border: none;
     background-color: aliceblue;
     height: 2rem;
     width: 9rem;
     border-radius: 0.3rem;
     margin-top: 2rem;
 }

 @media screen and (max-width:768px) {
    
    .hold{
        width: 100%;
        
    }
    
    .headhold {
        margin-top: 0rem;
         text-align: center;
         padding-top: 2rem;
         padding-bottom: 1.5rem;
         width: 100%;
         font-size: 2rem;
         color: white;
         background-image: linear-gradient(to right, #116a2dcf, #103a52c4);
     }
     .introhold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        margin-top: 2rem;
        width: 100%;
        flex-wrap: wrap;
        padding: 1rem;
    }
    .introhead {
     
        font-family: 'Fjalla One', sans-serif;
        text-align: center;
        margin-bottom: .5rem;
        font-size: 1rem;
        
    }
    .introL{
        width: 100%;
    }
    .introL p{
        width: 100%;
        font-size: .8rem;
       
       text-align: left;
        margin-left: 0rem;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .introImg{
        width: 100%;
        padding: 1rem;
        border-radius: .8rem;
        margin-left: 0;
    }
    
    .introhead2 {
        margin-top: 3rem;
        text-align: center;
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.1rem;
        font-weight: 600;
        width: 100%;
       
    }
   
    .introhead3 {
        margin-top: 1rem;
        text-align: center;
        font-family: 'Fjalla One', sans-serif;
        font-size: .8rem;
        font-weight: 500;
        margin-bottom: 1rem;
        width: 100%;
    }
   
    .introhead4 {
        font-size: .8rem;
        text-align: left;
        width: 100%;
        margin-left: 0rem;
        padding: 1rem;
        padding-top: 0;
        
    }
    .bgimg {
        margin-top: 2rem;
        padding-top: 2rem;
        padding-bottom: 1rem;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-image: url("./asset/about2.jpg");
        background-size: cover;
        height: 47rem;
        width: 100%;
        margin-left: 0rem;
        padding-left: 0rem;
       
        
        
        
    }
    .r{
        display: flex;
        flex-direction: row;
        gap: 0rem;
    }
    .top{
        display: flex;
        flex-direction:column;  
        justify-content: space-around;
        gap: 0rem;
        
    }
    
    .advHead {
        margin-top: 2rem;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
        font-family: 'Fjalla One', sans-serif;
        margin-bottom: 1rem;
    }
    .adv{
        width: 100%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }
    .grow{
        width: 100%;
    }
    .growTxt{
        font-size: 1.3rem;
        margin-top: 1rem;
    }
    .growBtn {
        margin: auto;
        display: block;
        border: none;
        background-color: aliceblue;
        height: 2rem;
        width: 8rem;
        font-size: .7rem;
        border-radius: 0.3rem;
        margin-top: 2rem;
    }
    
 }