.main{
    background-color: #103a52;
    display: flex;
    width: 100%;
    color: white;
    justify-content: flex-start;
    gap: 2rem;
    padding: 8px ;
    font-size: .9rem;
    font-weight: 400;
    position: sticky;
    top: 0;
    z-index: 99;
    
   
}
.email {
    margin-left: 6rem;
}

.mobile {
    transform: translateX(3rem);
}

.tdecor{
    text-decoration: none;
    color: white;
}
.icon{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    transform: translateX(10rem);
    gap: 1rem;
}
.icon a{
    text-decoration: none;
    color: white;
}
@media screen and (max-width: 768px) {
    .main{
        background-color: #103a52;
        display: flex;
        flex-direction: row;
        gap: .5rem;
        width: 100%;
        color: white;
        justify-content: space-around;
        padding: 10px ;
        font-size: .5rem;
        font-weight: 400;
        position: sticky;
        top: 0;
        z-index: 99;
       
    }
    .email {
        margin-left: 1rem;
    }
    
    .mobile {
        transform: translateX(0rem);
    }
    .time {
        transform: translateX(0rem);
    }
    
}