.heroImage img{
    width: 100%;
    height: 90vh;
    
    
}
.heroImage {
    position:relative;
  }
  
  
  .heroImage::after {
    content: '';
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 90vh;
    background: linear-gradient(120deg,#00000089 ,#0000008c);
  }
  @media screen and (max-width:768px) {
    .heroImage img{
      width: 100%;
      height: 40vh;
      
      
  }
    .heroImage::after {
      content: '';
      position: absolute;
      left: 0; top: 0;
      width: 100%; height: 40vh;
      background: linear-gradient(120deg,#00000089 ,#0000008c);
    }
    
  }
  