.container{
    display: flex;
    flex-direction: row;
    gap: 3rem;
    width: 100%;
    padding-bottom: 4rem;
}
.bg{
    margin-top: 0rem;
    background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
    padding-top: 5rem;
    padding-bottom: 5rem;
}
.bg h1{
    color: white;
    font-size: 3rem;
    
    
}
.container{
    margin-top: 3rem;
}
.left h1{
    font-family: 'Fjalla One', sans-serif;
    text-align: left;
    margin-bottom: 1rem;
}
.left p{
    width: 35rem;
    text-align: left;
    font-size: .9rem;
}
.key h4{
    text-align: left;
    margin: 1rem 0rem;

}
.key p{
    text-align: left;
    font-size: .9rem;
}
.key i{
    margin-right: .5rem;
}
@media screen and (max-width:768px) {
    .container{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        padding-bottom: 0rem;
        padding: 1rem;
        margin-top: 1rem;
    }
    .bg{
        margin-top: 0rem;
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .bg h1{
        color: white;
        font-size: 2rem;
        
        
    }
    .left h1{
        font-family: 'Fjalla One', sans-serif;
        text-align: left;
        margin-bottom: 1rem;
        font-size: 1.2rem;
    }
    .left p{
        width: 100%;
        text-align: left;
        font-size: .8rem;
    }
    .key h4{
        text-align: left;
        margin: 1rem 0rem;
    
    }
    .key p{
        text-align: left;
        font-size: .8rem;
    }
    .key i{
        margin-right: .5rem;
    }
    .right img{
        width: 90%;
    }
}