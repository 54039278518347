.container2{
    display: flex;
    flex-direction: column;
}
.bg{
    background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
    width: 100%;
    height: 15rem;
    position: relative;
    margin-top: 0rem;
}
.heading{
    position: relative;
    display: flex;
    text-align: center;
    transform: translateY(-9rem);
    color: white;
    font-family: 'Fjalla One', sans-serif;
    font-size: 1.5rem;
}
.thankyouMsg{
    color: white;
    background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
    padding: 1rem 4rem;
    font-weight: 500;
    width: 50rem;
    display: flex;
    position: relative;
    align-items: center;
    text-align: center;
    margin-bottom: 2rem;
}
.thankyouMsg p{
text-align: center;

}
.hmbtn  p{
    background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
    padding: 1rem 4rem;
    color: white;
    font-weight: 700;
   
}
.noul{
    text-decoration: none;
}
@media screen and (max-width:768px) {
    .bg{
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        width: 100%;
        height: 8rem;
        position: relative;
        margin-top: 0rem;
    }
    .heading{
        position: relative;
        display: flex;
        text-align: center;
        transform: translateY(-6rem);
        color: white;
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.5rem;
    }
    .thankyouMsg{
        color: white;
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        padding: 1rem 4rem;
        font-weight: 500;
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        text-align: center;
        margin-bottom: 2rem;
    }
    .thankyouMsg p{
    text-align: center;
    font-size: .8rem;
    
    }
    .hmbtn  p{
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        padding: 1rem 4rem;
        color: white;
        font-weight: 700;
        font-size: .8rem;
       
    }
    .noul{
        text-decoration: none;
    }
}