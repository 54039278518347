@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,800;1,700;1,800&display=swap');

.container{
    position: relative;
    transform: translateY(-18rem);
    margin-bottom: -10rem;   
}
.head h1{
    font-size: 3rem;
    font-family: 'Fjalla One', sans-serif;
    color: white;
}
.text p{
    font-weight: 500;
    margin-top: 3rem;
    color: white;
}
.btnlearn{
    text-decoration: none;
    color: white;
}
.btn button{
    margin-top: 1rem;
    background: transparent;
    border-radius: 13px;
    border: 2px dashed white;
    padding: 8px 15px;
    color: white;
    cursor: pointer;
}
.btn button:hover{
    background: linear-gradient( #116a2dcf,#103a52c4)
}

@media screen and (max-width:768px) {
    .head h1{
        font-size: 1.7rem;
        font-family: 'Fjalla One', sans-serif;
        color: white;
        margin-top: 6rem;
    }
    .text p{
        font-weight: 300;
        margin-top: 2rem;
        color: white;
        font-size: .9rem;
    }
    .btn button{
        margin-top: 1rem;
        background: transparent;
        border-radius: 13px;
        border: 2px dashed white;
        padding: 5px 15px;
        color: white;
        cursor: pointer;
    }
}