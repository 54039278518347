.container{
    width: 100%;
    margin-top: 0rem;
    position: relative;
}
.bg{
    background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
    width: 100%;
    height: 15rem;
    position: relative;
}
.heading{
    position: relative;
    display: flex;
    text-align: center;
    transform: translateY(-9rem);
    color: white;
    font-family: 'Fjalla One', sans-serif;
    font-size: 1.5rem;
}
@media screen and (max-width:768px) {
    .bg{
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        width: 100%;
        height: 6rem;
        position: relative;
    }
    .heading{
        position: relative;
        display: flex;
        text-align: center;
        transform: translateY(-5rem);
        color: white;
        font-family: 'Fjalla One', sans-serif;
        font-size: 1.1rem;
    }
}