.card {
    padding-top: 1rem;
    width: 14rem;
    height: 10rem;
    border: 1px solid rgb(232, 232, 232);
    margin-top: 2rem;
}

.icon {
    margin: auto;
    display: block;
    width: 4rem;
}

.txt {
    margin-top: 1rem;
    font-family: 'poppins', sans-serif;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    text-align: center;
    color: gray;
    font-size: .8rem;
}
@media screen and (max-width:768px) {
    .card {
        padding-top: 1rem;
        width: 8rem;
        height:11.5rem;
        border: 1px solid rgb(232, 232, 232);
        margin-top: .5rem;
    }
    .icon {
        margin: auto;
        display: block;
        width: 2.5rem;
    }
    .txt {
        margin-top: 1rem;
        font-family: 'poppins', sans-serif;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        text-align: center;
        color: gray;
        font-size: .7rem;
    }
}