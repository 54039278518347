.container{
    display: flex;
    margin-left: 0;
    width: 100%;
    margin-bottom: 4rem;
    
    
}
.card{
    display: flex;
    flex-direction: column;
    width: 11rem;
    
    border: 1px solid #ede7e7;
    padding: 1rem;
    padding-top:1rem;
    transition: .5s;
}
.image{
    border-radius: 50%;
    padding: 1.8rem;
    border: 2px solid white;
    border-spacing: 3rem;
    background: linear-gradient( #487fb7,#69a9e8);
    
    transition: .5s;
}
.image i{
    font-size: 1.5rem;
    color: white;
    position: absolute;
    text-align: center;
    transform: translate(-50%,-50%);
    transition: .3s;
    
    
    
}
.heading p{
    font-size: .8rem;
    font-weight: 500;
    margin-top: 2rem;
}


.image:hover{
 
 border: 2px solid rgb(126, 246, 126);
 
 
}
@media screen and (max-width:768px) {
    .container{
        display: flex;
        flex-wrap: wrap;
        margin-left: 0;
        width: 100%;
        margin-bottom: 2rem;
        overflow: hidden;
        
        
    }
    .card{
        display: flex;
        flex-direction: column;
        width: 10rem;
        
        border: 1px solid #ede7e7;
        padding: 1rem;
        padding-top:1rem;
        transition: .5s;
    }
    
}

