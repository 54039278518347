.container{
    
    width: 100%;
    position: relative;
    margin-top: 4rem;
}
.head h1{
    font-family: 'Fjalla One', sans-serif;
    background: -webkit-radial-gradient(#487fb7,#2d8cc3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: left;
}

.left img{
    width: 30rem;
    border-radius: .8rem;
}
.divBar{
    display: flex;
    width: 80px;
    background: linear-gradient(#487fb7,#2d8cc3);
    height: 6px;
    border-radius: 10px;
    margin: 10px 0px;

  }
  .row{
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
  }
  .right p{
    width: 35rem;
    text-align: left;
  }
  @media screen and (max-width:768px) {
    
    .row{
      display: flex;
      flex-direction: column-reverse;
      row-gap: 1rem;
      padding: 1rem;
      position:relative;
      

    }
    .left img{
      width: 21rem;
    }
    
    .head h1{
      display: flex;
        text-align: center;
        justify-content: center;
        font-family: 'Fjalla One', sans-serif;
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.5rem;
      
    }
    .divBar{
      display: flex;
      width: 80px;
      background: rgb(26, 153, 47);;
      height: 6px;
      border-radius: 10px;
      margin: 10px 0px;
      margin-left: 9rem;
  
    }
    .right{
      width: 100%;
      
    }
    .right p{
      width: 100%;
    }
    .cont{
      font-size: .9rem;
      
        
    
    }
  }
  