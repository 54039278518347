.AccHold {
    margin-top: 4rem;
    padding-top: 4rem;
    padding-bottom: 4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 1rem;
    background-color: rgb(243, 241, 241);
}

.left {
    gap: .7rem;
    width: 15rem;
    display: flex;
    flex-direction: column;
}

.right {
    width: 41rem;
    height: 29rem;
    background-color: white;
    border-radius: 2rem;
    padding: 2rem;
    
}



.AccBtn {
    border: none;
    width: 11rem;
    height: 3.5rem;
    border-radius: 0.3rem;
    background-color: white;
    color: black;
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 1rem;
    height: 4rem;
}

.AccBtn11 {
    border-radius: 0.3rem;
    background-color: rgb(8, 146, 171);
    padding: 1rem;
    height: 4rem;
}

.AccBtn1 {
    border-radius: 0.3rem;
    border: none;
    width: 11rem;
    height: 4rem;
    color: white;
    background-color: rgb(8, 146, 171);
    margin-bottom: 1rem;
    font-weight: bold;
    padding: 1rem;
}

.AccBtn:hover {
    background-color: rgb(8, 146, 171);
}

.accContainIn {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 0.5rem;
    margin-left: 0rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.rightIcon {
    margin-top: 0rem;
    margin-right: 0.3rem;
    width: 1.2rem;
    height: 1.2rem;
    
}
.rightTxt{
    text-align: left;
}
@media screen and (max-width:768px) {
    .AccHold {
        margin-top: 4rem;
        padding-top: 4rem;
        padding-bottom: 4rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: .5rem;
        background-color: rgb(243, 241, 241);
        padding: 1rem;
        font-size: .6rem;
    }
    .left {
        gap: .5rem;
        width: 10rem;
        
        display: flex;
        flex-direction: column;
    }
    .AccBtn {
        border: none;
        width: 8rem;
        height: 3.5rem;
        border-radius: 0.3rem;
        background-color: white;
        color: black;
        margin-bottom: 1rem;
        font-weight: bold;
        padding: 1rem;
        height: 4rem;
        font-size: .6rem;
    }
    
    .AccBtn11 {
        border-radius: 0.3rem;
        background-color: rgb(8, 146, 171);
        padding: 1rem;
        height: 4rem;
        font-size: .6rem;
    }
    
    .AccBtn1 {
        border-radius: 0.3rem;
        border: none;
        width: 8rem;
        height: 4rem;
        color: white;
        background-color: rgb(8, 146, 171);
        margin-bottom: 1rem;
        font-weight: bold;
        padding: 1rem;
        font-size: .6rem;
        
    }
    .right {
        width: 41rem;
        height: 44rem;
        background-color: white;
        border-radius: 2rem;
        padding: 2rem;
        
    }
}