.hold {
    font-family: 'poppins', sans-serif;
}

.headhold {
    margin-top: 0rem;
    padding-top:5rem;
    padding-bottom: 5rem;
    background-image: linear-gradient(to right, #116a2dcf, #103a52c4);

}

.hold2 {
    display: flex;
    margin-top: 3rem;
    flex-direction: row;
    gap: 3rem;
}

.left {
    width: 35rem;
    
}

.lefthead {
    font-family: 'Fjalla One', sans-serif;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;
    margin: 1rem 0;
}

.leftTxt {
    font-size: .9rem;
    font-family: 'poppins', sans-serif;
    width: 35rem;
    text-align: left;
}
.leftTxt i{
    margin-right: .6rem;
}

.head {
    text-align: center;
    font-family: 'Fjalla One', sans-serif;
    font-size: 3rem;
    font-weight: 600;
    color: white;
}

.intro {
    
    text-align: left;
    font-family: 'Fjalla One', sans-serif;
    margin-bottom: 1rem;
    font-weight: 500;
}

.image {
    border-radius: .8rem;
    
    width: 30rem;
    
}
.processHold{
    margin-top: 4rem;
}

.processhead {
    text-align: center;
    font-family: 'Fjalla One', sans-serif;
    font-size: 1.4rem;
    color: green;
    margin-bottom: 1rem;
    

}

.processhead2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1rem;
    font-weight: 400;
}

.processStepsIn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15rem;
    margin-bottom: 1rem;
}

.processStepsIn2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6rem;
    margin-bottom: 2rem;
}

.processStepsIn3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6rem;
    font-size: .9rem;
    font-weight: 500;
}

.processIcon {
    width: 3rem;
}