.container{
    margin-top: 3rem;
}
.heroimg{
    width: 100%;
    height: 25rem;
    justify-content: center;
    display: flex;
    position: relative;
}
.heroimg::after{
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient( rgba(21, 89, 126, 0.915),#05759ee0);
}


@media screen and (max-width:768px) {
    .heroimg{
        width: 100%;
        height: 15rem;
        justify-content: center;
        display: flex;
        position: relative;
    }
    .heroimg img{
        width: 100%;
    }
}