@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,800;1,700;1,800&display=swap');

.container{
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    position: relative;
}
.head h1{
    font-family: 'Fjalla One', sans-serif;
    background: -webkit-radial-gradient(#487fb7,#2d8cc3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.divBar{
    display: flex;
    width: 120px;
    background: linear-gradient(#487fb7,#2d8cc3);
    height: 6px;
    border-radius: 10px;
    margin: 10px 0px;

  }
  .row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 2rem;
  }
  .row img{
    width: 10rem;
  }
  @media screen and (max-width:768px) {
    .row{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap:.5rem;
      column-gap: 2rem;
      padding: 1rem;
    }
    .row img{
      width: 5rem;
    }
  }