@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,800;1,700;1,800&display=swap');

.container{
    background:transparent;
    text-align: left;
    width: 41rem;
    height: 20rem;
    padding: 1rem;
    
    
    
}
.linkstyle{
    text-decoration: none;
    color: rgb(5, 82, 134);
    font-size: .8rem;
}
 .head h1{
    font-family: 'Fjalla One', sans-serif;
    position: relative;
    color: white;
    font-size: 2.5rem;
    text-align: left;
    width: 40rem;
    padding: .6rem;
    letter-spacing: 1px;
}
 .text p{
    font-family: 'Fjalla One', sans-serif;
    width: 40rem;
    color: white;
    font-size: 1.1rem;
    text-align: left;
    padding: .6rem;
    
}
.btn button{
    font-family: 'Fjalla One', sans-serif;
    position: relative;
    padding: 10px;
    text-align: left;
    border-radius: 5px;
    margin-top: 1rem;
    margin-left: -30rem;
    font-weight: 500;
    border: 0;
    letter-spacing: 1px;
    color: green;
    cursor: pointer;
}

@media screen and (max-width:768px) {
    .container{
        background:transparent;
        text-align: left;
        width: 20rem;
        height: 12rem;
        padding: 1rem;
        
        
        
    }
    .head h1{
        font-family: 'Fjalla One', sans-serif;
        position: relative;
        color: white;
        font-size: .9rem;
        text-align: left;
        width: 20rem;
       
        padding: .6rem;
        letter-spacing: 1px;
    }
    .text p{
        font-family: 'Fjalla One', sans-serif;
        width: 20rem;
        color: white;
        font-size: .6rem;
        text-align: left;
        padding: .6rem;
        
    }
    .btn button{
        font-family: 'Fjalla One', sans-serif;
        position: relative;
        padding: 5px;
        text-align: left;
        border-radius: 5px;
        margin-top: .5rem;
        margin-left: 0rem;
        
        font-weight: 500;
        border: 0;
        letter-spacing: 1px;
        color: green;
        cursor: pointer;
    }
   
    
}