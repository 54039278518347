@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,800;1,700;1,800&display=swap');

.container{
    width: 100%;
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    gap: 2rem;
    

    
}
.left{
    width: 40%;
}
.head h1{
    display: flex;
    text-align: left;
    justify-content: left;
    font-family: 'Fjalla One', sans-serif;
    background: -webkit-radial-gradient(#487fb7,#2d8cc3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.paragraph p{
    text-align: left;
    font-size: .9rem;
}
.divBar{
    display: flex;
    width: 120px;
    background: linear-gradient(#487fb7,#2d8cc3);
    height: 6px;
    border-radius: 10px;
    margin: 10px 0px;

  }
.right{
    width: 40%;
}
.right img{
    width: 35rem;
    border-radius: .8rem;
}

@media screen and (max-width:768px) {
    .container{
        width: 100%;
        margin-top: 0rem;
        display: flex;
        flex-direction:column;
        gap: 1rem;
        padding: 1rem;
    }
    .left{
        width: 100%;
    }
    .head h1{
        display: flex;
        text-align: center;
        justify-content: center;
        font-family: 'Fjalla One', sans-serif;
        background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 1.5rem;
        margin-top: -4rem;
    
    }
    .divBar{
        display: flex;
        width: 120px;
        background: rgb(26, 153, 47);;
        height: 6px;
        border-radius: 10px;
        margin: 10px 0px;
        margin-left: 7rem;
    
      }
    .paragraph p{
        text-align: left;
        font-size: .9rem;
        width: 100%;
    }
    .right{
        width: 100%;
    }
    .right img{
        width: 21rem;
        border-radius: .8rem;
    }
    
}