.container {
    margin-top: 0rem;
    position: fixed;
    z-index: 95;
    background-color: white;
    padding: 3rem 3rem;
    box-shadow: 1px 2px 5px rgba(0, 0, 0, .2);
    transition: 2s;
    
    
    
    
}
.row{
    display: flex;
    justify-content: space-between;

}
.card{
    
    width:400px;
    padding: 13px;
    
    display: flex;
}
.left img{
    
   width: 5rem;
    
    
}

.right h4{
    font-size: 1.2rem;
    font-weight: 400;
    text-align: start;
    margin-left: 1rem;
    margin-bottom: -1rem;
    
    
}
.right p{
    font-size: .8rem;
    padding: 1rem;
    padding-bottom: 0;
    text-align: left;
   
}
.right .btn{
cursor: pointer;
float: left;
font-size: .7rem;
margin-top: .5rem;  
margin-left: 1rem;  
}


@media screen and (max-width:768px) {
    .container {
        margin-top: 0rem;
        position: fixed;
        z-index: 1;
        background-color: white;
        padding: 1rem 1rem;
        box-shadow: 1px 2px 5px rgba(0, 0, 0, .2);
        transition: 2s;
        width: 100%;
        }
        .row{
            display: flex;
            justify-content: space-around;
            flex-direction: row;
            flex-wrap: wrap;
            
            
            width: 100%;
        
        }
        .card{
    
            width:120px;
            padding: 1rem;
            
            display: flex;
        }
        .left img{
    
            width: 2.5rem;
            margin-right: 0rem;
            
             
             
         }
         .right h4{
            font-size: .6rem;
            font-weight: 400;
            text-align: start;
            margin-left: .7rem;
            margin-bottom: 0rem;
            
            
        }
        .right p{
            font-size: .4rem;
            padding: .8rem;
            padding-bottom: 0;
            text-align: left;
            width: 8rem;
           
        }
        .right .btn{
            cursor: pointer;
            float: left;
            font-size: .4rem;
            margin-top: .5rem;  
            margin-left: 1rem;  
            }
    
}