.container{
    overflow: hidden;
    position: absolute;
    width: 40rem;
    transform: translateY(-23rem);
    margin-left: 5rem;
}
@media screen and (max-width:768px) {
    .container{
      background: transparent;
      width: 21rem;
      height: 12rem;
      overflow: hidden;
      position: absolute;
      margin-top: 12rem;
      margin-left: 2rem;
      
    }
    
}