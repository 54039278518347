@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');

.container{
    width: 100%;
    font-family: 'Fjalla One', sans-serif;
    padding:3rem;
    padding-top:0 ;
}
.container h1{
    font-family: 'Fjalla One', sans-serif;
    margin-bottom: 2rem;
}
.container .profile1{
    box-shadow: 1px 1px 3px rgb(55, 55, 55);
    margin: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 1rem;
    align-items: flex-start;
}
.container .profile1 .photo{
    width: 12rem;
    height: 14rem;
    background: blueviolet;
}
.container .profile1 .info{
    width: 60%;
    text-align: left;
}
.container .profile1 .info p{
    font-size: .9rem;
    text-align: left;
}
.container .profile1 .info h2{
    text-align: left;
    font-size: 1.3rem;
}
.stle{
    width: 100%;
}
