.container{
    position: relative;
    background: -webkit-radial-gradient(#cfedffc4,#cfedffc4);
    width: 100%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding-bottom: 5rem;
}
.head h1{
    padding-top: 3rem;
    font-family: 'Fjalla One', sans-serif;
    background: -webkit-radial-gradient(#116a2dcf,#103a52c4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
}
.divBar{
    display: flex;
    width: 120px;
    background: rgb(26, 153, 47);;
    height: 6px;
    border-radius: 10px;
    margin: 10px 0px;

  }
  .bgwhite{
    background: white;
    padding: 2rem;
  }
  .fill h4{
    text-align: left;
    margin: 1rem 0rem;
    

  }
  .form input{
    width: 15rem;
    height: 3rem;
    background: rgb(228, 226, 226);
    border: 0;
    
  }
  .r1{
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    
  }
  .r2{
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    
    
    
  }
  .form select{
    width: 32rem;
    height: 3rem;
    background: rgb(228, 226, 226);
    border: 0;
  }
  .selectgap{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  .message input{
    width: 32rem;
    height: 5rem;
    background: rgb(228, 226, 226);
    border: 0;
  }
  .form button p{
    background: radial-gradient(#116a2dcf,#103a52c4);
    padding: .6rem 5rem;
    color: white;
    font-weight: 600;
    border: 0;
    cursor: pointer;
   
  }
  .messages{
    width: 100%;
    position: relative;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    margin-top: 2rem;
  }
  .messages .success{
    position: absolute;
    color: green;
    animation: buttons .3s linear;
    display: none;
  }
  .messages .danger{
    position: absolute;
    color: red;
    transition: .3s;
    animation: buttons .3s linear;
    display: none;

  }
  @keyframes buttons{
    0%{
      transform: scale(0.1);
    }
    50%{
      transform: scale(0.5);
    }
    100%{
      transform: scale(1);
    }
  }
  
    
  @media screen and (max-width:768px) {
    .container{
      position: relative;
      background: -webkit-radial-gradient(#b6e4c4cf,#cfedffc4);
      width: 100%;
      margin-top: 3rem;
      display: flex;
      flex-direction: column;
      row-gap: 1rem;
      padding-bottom: 5rem;
  }
  .bgwhite{
    width: 100%;
    padding: 1rem;
  }
  .r1{
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    padding-left: .5rem;
    
  }
  .r2{
    display: flex;
    flex-direction: row;
    column-gap: 1rem;
    padding-left: .5rem;
    }
    .form input{
      width: 11rem;
      height: 3rem;
      background: rgb(228, 226, 226);
      border: 0;
      font-size: .7rem;
      
    }
    .form select{
      width: 22rem;
      height: 3rem;
      background: rgb(228, 226, 226);
      border: 0;
      font-size: .7rem;
      
    }
    .message input{
      width: 22rem;
      height: 5rem;
      background: rgb(228, 226, 226);
      border: 0;
      font-size: .7rem;
      margin-top: -1rem;
    }
    .selectgap{
      display: flex;
      flex-direction: column;
      row-gap: .5rem;
    }
    .form button p{
      background: radial-gradient(#116a2dcf,#103a52c4);
      padding: .3rem 3rem;
      color: white;
      font-weight: 600;
      border: 0;
    }
    .messages .danger{
      font-size: .8rem;
    }
    .messages .success{
      font-size: .8rem;
    }



  }
  