@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&family=Poppins:ital,wght@0,800;1,700;1,800&display=swap');


.container{
   margin-top: 3rem;
    display: flex;
    flex-direction: column;
    width: 100%;

}
.heading h1{
    font-family: 'Fjalla One', sans-serif;
    letter-spacing: 2px;
    background: -webkit-linear-gradient(#487fb7,#2d8cc3);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.heading p{
    margin-top: 1rem;
    position: relative;
    width: 90%;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: .9rem;
}

.linehlder{
    display: flex;
   justify-content: center;
}
.divBar{
    width: 150px;
    background: linear-gradient(#487fb7,#2d8cc3);
    height: 6px;
    border-radius: 10px;
}
.row{
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 1rem;
    row-gap: 1rem;
    
    
}
.card{
    background-color:transparent;
    width: 22rem;
    height: 17rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all .3s ease-in-out;
    border-radius: 12px;
    
}
.card .ico{
    
    padding: 2.4rem;
    background: linear-gradient( #487fb7,#69a9e8); 
    border-radius: 50%;
    transform: rotate(360deg) linear infinite;
}
.ico i{
    color: white;
    position: absolute;
    font-size: 2rem;
    transform: translate(-50%,-50%);
}

.card .name p{
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1rem;
}
.card .text p{
    font-size: .8rem;
    margin-top: .5rem;
}
.card button{
    border: 0;
    background: transparent;
    color: black;
    margin-top: 1rem;
    cursor: pointer;
}
.card:hover{
    background: linear-gradient(#cbffdccf,#2d8cc3);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .3);
}
.card:hover .ico {
    background: yellow;
    color: black;
}
.card:hover .ico i {
    
    color: black;
}
.card:hover .name p{
    color: white;
}
.card:hover .text p{
    color: white;
}
.card:hover button{
    color: yellow;
}

@media screen and (max-width:768px) {
    .container{
        overflow: hidden;
        width: 100%;
    }
}