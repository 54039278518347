.hold {
    margin: 2rem;
    width: 20rem;
    height: 13rem;
    background-color: white;
    border-radius: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.star {
    margin: auto;
    display: block;
    width: 3rem;
}

.head {
    text-align: center;
    font-size: 1rem;
    font-family: 'Fjalla One', sans-serif;
    margin-top: 1rem;
}

.txt {
    padding: 1rem;
    font-size: .8rem;
    text-align: center;
    font-family: 'poppins', sans-serif;
}

.btn {
    margin: auto;
    display: block;
    background-color: transparent;
    color: #103a52c4;
    border: none;

}
@media screen and (max-width:768px) {
    
    .hold {
        margin-top: 1rem;
        width: 9rem;
        height: 12rem;
        background-color: white;
        border-radius: 1rem;
        padding-bottom: 1rem;
        margin-left:1.6rem;
        
        
        
    }
    .star {
        margin: auto;
        display: block;
        width: 1.5rem;
    }
    .head {
        text-align: center;
        font-size: .8rem;
        font-family: 'Fjalla One', sans-serif;
        margin-top: 1rem;
    }
    .txt {
        padding: 1rem;
        font-size: .6rem;
        text-align: center;
        font-family: 'poppins', sans-serif;
        width: 9rem;
    }
}