.container {
    display: flex;
    flex-direction: row;
    gap: 4rem;
    margin-top: 3rem;
    background: whitesmoke;
    padding-top: 5rem;
    padding-bottom: 2rem;
    margin-bottom: -3rem;
    
}
.top{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    margin-top: 0rem;
    background: whitesmoke;
    padding-top: 0rem;
    padding-bottom: 2rem;
    margin-bottom: -3rem;
}
.left{
    display: flex;
    flex-direction: column;
    
}
.c1{
    display: flex;
    flex-direction: row;
    gap: 2rem;
   border: 0;
    width: 28rem;
    padding: 1rem;
    margin-bottom: 1.5rem;
    background: white;
}
.c1l i{
    font-size: 1.5rem;
    padding: 1rem;
    border: 1px dashed black;
    outline: 1px dashed green;
  outline-offset: 2px;
   
}
.r1{
    font-weight: 600;
    font-size: 1rem;
}
.r2{
    width: 20rem;
    font-size: .8rem;    
}

.right iframe{
    width: 32rem;
    height: 21rem;
    transform: translateY(-1rem);
    border: 0;
}
.bottom {
    width: 100%;
}
.bottom .row{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 3rem;
    padding-top:0 ;
}
.bottom .row .card{
    width: 22rem;
    height: 12rem;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .5);
    border-radius: 1rem;
    padding: 1rem;
    background-color: white;
}
.bottom .row .card p{
    font-size: .8rem;
    margin-top: 1rem;
}

@media screen and (max-width:768px) {
    .container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-top: 3rem;
        background: whitesmoke;
        padding-top: 5rem;
        padding-bottom: 2rem;
        margin-bottom: -3rem;
        width: 100%;
        
    }
    .c1{
        display: flex;
        flex-direction: row;
        gap: 1rem;
       border: 0;
        width: 100%;
        padding: .7rem;
        margin-bottom: 1.5rem;
        background: white;
    }
    .c1l i{
        font-size: .7rem;
        padding: .5rem;
        border: 1px dashed black;
        outline: 1px dashed green;
      outline-offset: 2px;
       
    }
    .r1{
        font-weight: 600;
        font-size: .7rem;
    }
    .r2{
        width: 10rem;
        font-size: .5rem;    
    }
    .right iframe{
        width: 100%;
        height: 14rem;
        transform: translateY(-1rem);
        border: 0;
    }
}